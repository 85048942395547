import NotifcationIcon from "@assets/svg/navigation/notification.svg?react"
import { Text, Group } from "@mantine/core"
import styles from "./NotificationBell.module.css"

interface NotificationBellProps {
	numNotis: number
}

export function NotificationBell({ numNotis = 0 }: NotificationBellProps) {
	return (
		<Group pos="relative">
			{numNotis > 0 && (
				<Group
					align="center"
					justify="center"
					className={styles.notificationBellBadge}
				>
					<Text span fz={12} fw={500} lh={1} c="var(--text-color-white)">
						{numNotis}
					</Text>
				</Group>
			)}
			<NotifcationIcon />
		</Group>
	)
}
