import {
	useQuery,
	useQueryClient,
	QueryClient,
	QueryClientProvider,
	UseQueryResult
} from "@tanstack/react-query"
import {
	Brand,
	VITE_AEM_API_KEY,
	VITE_AEM_CREDENTIALS,
	VITE_AEM_URL,
	VITE_AEM_USE_FALLBACK,
	VITE_AEM_USE_FALLBACK_WITH_EN
} from "../utils/constants.util"
import {
	AEMPage,
	AEMPageType,
	AEMComponentType,
	AEMComponentMapper,
	AEMNavigation,
	AEMAlert,
	AEMPagePaths,
	AEMContentParams,
	EntityPathParams
} from "./models/AEMPage.model"
import { useEffect, useState } from "react"
import {
	AppEvents,
	AvailableLanguages,
	QueryKeys,
	StorageKeys
} from "@utils/user-config"
import homeData from "./models/fallbackData/home.json"
import loginData from "./models/fallbackData/login.json"
import footerData from "./models/fallbackData/footer.json"
import seeOurShipsData from "./models/fallbackData/seeOurShips.json"
import { useAuthContext } from "../context/AuthProvider"
import { deepMerge } from "@utils/deepMerge"
import axios from "axios"
import secureLocalStorage from "react-secure-storage"
import seeOurShipsOverview from "./models/fallbackData/seeOurShipsOverview.json"
import seeOurShipsFleet from "./models/fallbackData/seeOurShipsFleet.json"
import seeOurShipsDining from "./models/fallbackData/seeOurShipsDining.json"
import seeOurShipsSpecialtyDining from "./models/fallbackData/seeOurShipsSpecialtyDining.json"
import seeOurShipsComplimentaryDining from "./models/fallbackData/seeOurShipsComplimentaryDining.json"
import { AEMStateroomDetails, AEMStaterooms } from "./models/AEMStateroom.model"
import { AEMDeckplan } from "./models/AEMDeckplan.model"
import { AEMShipProfile } from "./models/AEMShipProfile"

export const queryClient = new QueryClient()

const fallbackData: Partial<Record<string, unknown>> = {
	[AEMPageType.HOME]: homeData,
	[AEMPageType.LOGIN]: loginData,
	[AEMPageType.FOOTER]: footerData,
	[AEMPageType.SEE_OUR_SHIPS]: seeOurShipsData,
	[AEMPageType.SEE_OUR_SHIPS_OVERVIEW]: seeOurShipsOverview,
	[AEMPageType.SEE_OUR_SHIPS_FLEET]: seeOurShipsFleet,
	[AEMPageType.SEE_OUR_SHIPS_DINING]: seeOurShipsDining,
	[AEMPageType.SEE_OUR_SHIPS_SPECIALTY_DINING]: seeOurShipsSpecialtyDining,
	[AEMPageType.SEE_OUR_SHIPS_COMPLIMENTARY_DINING]:
		seeOurShipsComplimentaryDining
}

const AVAILABLE_UNAUTHENTICATED_PAGES = [
	AEMPageType.LOGIN,
	AEMPageType.FOOTER,
	AEMPageType.DICTIONARY,
	AEMPageType.LANGUAGES,
	AEMPageType.NAVIGATION_FOOTER,
	AEMPageType.PAGES_TERMS_OF_USE,
	AEMPageType.PAGES_PRIVACY_POLICY
]

type AEMResponse =
	| AEMPage
	| AEMNavigation
	| AEMAlert
	| AEMStaterooms
	| AEMDeckplan
	| AEMShipProfile
	| AEMStateroomDetails

const getAEMContent = async (
	page: AEMPageType,
	options?: AEMOptions
): Promise<
	| AEMPage
	| AEMNavigation
	| AEMStaterooms
	| AEMDeckplan
	| AEMShipProfile
	| AEMStateroomDetails
> => {
	// Fetch primary language content
	let data = await fetchAEMContent(page, options)

	// If locale is not English, fetch English content as fallback
	if (
		options?.queryParams?.locale !== AvailableLanguages.EN_US &&
		VITE_AEM_USE_FALLBACK_WITH_EN
	) {
		const englishContent = await fetchAEMContent(page, options)
		// Deep merge the contents, with primary language taking precedence
		data = deepMerge(englishContent, data)
	}

	return data
}

const valueToLowerIfRequired = (key: string, value: string): string => {
	if (["country", "office", "role"].includes(key)) {
		return value.toLowerCase()
	}
	return value
}

const fetchAEMContent = async (
	page: AEMPageType,
	options?: AEMOptions
): Promise<
	| AEMPage
	| AEMNavigation
	| AEMStaterooms
	| AEMDeckplan
	| AEMShipProfile
	| AEMStateroomDetails
> => {
	try {
		const queryParams = options?.queryParams
		const credentials = btoa(VITE_AEM_CREDENTIALS)
		const contentPath = queryParams?.contentPath ?? "cruising-power"
		let path = AEMPagePaths[page]
		if (options?.brand) {
			path = path.replace(EntityPathParams.BRAND, options.brand)
		}
		if (options?.ship) {
			path = path.replace(EntityPathParams.SHIP, `-${options.ship}`)
		} else if (options?.brand) {
			path = path.replace(EntityPathParams.SHIP, "")
		}
		const baseUrl = new URL(
			`${VITE_AEM_URL}/api/royal/content/${contentPath}${path}${(
				options?.pathParams || []
			)
				.map((value) => `/${value}`)
				.join("/")}`
		)

		const queryParamsObject = {
			locale: queryParams?.locale,
			...queryParams
		}

		baseUrl.search = new URLSearchParams(
			Object.entries(queryParamsObject)
				.filter(([_, value]) => value !== undefined)
				.map(([key, value]) => [
					key,
					valueToLowerIfRequired(key, value as string)
				])
		).toString()

		const { data } = await axios.get<AEMResponse>(baseUrl.toString(), {
			headers: {
				Apikey: VITE_AEM_API_KEY,
				Authorization: `Basic ${credentials}`,
				"edge-cache-tag": `${page}-${queryParams?.locale}`
			},
			validateStatus: (status) => status === 200
		})

		if (page === AEMPageType.DICTIONARY) {
			return {
				items: [
					{ components: [{ type: AEMComponentMapper.APP_DICTIONARY, ...data }] }
				]
			}
		}
		if (page === AEMPageType.NAVIGATION_HOME && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.NAVIGATION_FOOTER && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.FOOTER && "items" in data) {
			if (!data.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: data.items.map((item) => ({
							type: AEMComponentMapper.APP_FOOTER,
							...item
						}))
					}
				]
			}
		}

		if (page === AEMPageType.NAVIGATION_GLOBAL && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.NAVIGATION_SIDEBAR && "0" in data) {
			if (!data[0]?.children) {
				throw new Error("Invalid data structure: missing items")
			}
			return data
		}

		if (page === AEMPageType.LANGUAGES && "items" in data) {
			if (!data.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: [
							{ type: AEMComponentMapper.APP_LANGUAGES, languages: data.items }
						]
					}
				]
			}
		}

		if (page === AEMPageType.ALERTS && "data" in data) {
			if (!data.data?.componentAlertBannerList?.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.APP_ALERTS,
								items: data?.data?.componentAlertBannerList?.items
							}
						]
					}
				]
			}
		}

		if (page === AEMPageType.NOTIFICATIONS && "data" in data) {
			if (!data.data?.componentNotificationsList?.items) {
				throw new Error("Invalid data structure: missing items")
			}
			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.APP_NOTIFICATIONS,
								items: data?.data?.componentNotificationsList?.items
							}
						]
					}
				]
			}
		}

		if (
			(page === AEMPageType.OFFERS || page === AEMPageType.EXPIRED_OFFERS) &&
			"items" in data
		) {
			if (options?.pathParams?.length) {
				if ("items" in data) {
					return {
						items: [
							{
								components: [
									{
										type: AEMComponentMapper.OFFER_DETAILS,
										...data.items?.[0]
									}
								]
							}
						]
					}
				}
				throw new Error("Invalid data structure: missing items")
			}

			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.OFFERS,
								items: data?.items
							}
						]
					}
				]
			}
		}

		if (page === AEMPageType.TAGS && "items" in data) {
			return {
				items: [
					{
						components: data.items?.[0]?.items
							? data.items.map((item) => ({
									type: item.id ?? "",
									...item
								}))
							: [
									{
										type: AEMComponentMapper.TAGS_OFFER_COLLECTION,
										items: data.items
									}
								]
					}
				]
			}
		}

		if (page === AEMPageType.DINING && "items" in data) {
			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.DINING_CARD,
								items: data?.items
							}
						]
					}
				]
			}
		}

		if (page === AEMPageType.SEE_OUR_SHIPS_OVERVIEW && "items" in data) {
			return {
				items: [
					{
						components: [
							...(data.items[0].components as AEMComponentType[]),
							data.items[0].detailModel as AEMComponentType
						]
					}
				]
			}
		}

		if (page === AEMPageType.SOS_ENTITY_ROOMS && "items" in data) {
			return {
				items: [
					{
						components: [
							{
								type: AEMComponentMapper.SOS_ENTITY_DETAILS_MODEL,
								...data.items?.[0]
							}
						]
					}
				]
			}
		}
		if (page === AEMPageType.STATEROOM_SUBTYPE && "stateroomType" in data) {
			return { stateroomType: data.stateroomType }
		}

		if (page === AEMPageType.DECKPLAN && "deckContent" in data) {
			return { deckContent: data.deckContent }
		}

		if (page === AEMPageType.SHIP_PROFILE_CONTENT && "profile" in data) {
			return { profile: data.profile }
		}

		if (
			page === AEMPageType.STATEROOM_SUBTYPE_DETAIL &&
			"stateroomSubtype" in data
		) {
			return { stateroomSubtype: data.stateroomSubtype }
		}

		if (
			!("items" in data) ||
			!data.items ||
			!data.items[0] ||
			!data.items[0].components
		) {
			throw new Error("Invalid data structure: missing items[0].components")
		}

		if (
			!("items" in data) ||
			!data.items ||
			!data.items[0] ||
			!data.items[0].components
		) {
			throw new Error("Invalid data structure: missing items[0].components")
		}

		return data
	} catch (error) {
		console.error(`Error fetching AEM content for ${page}:`, error)

		const fallbackPageData = fallbackData[page as AEMPageType]
		// Santiago Ponce: Aem fallback meant only for debugging pruposes, do not allow on production
		if (fallbackPageData && VITE_AEM_USE_FALLBACK) {
			console.warn(`Using fallback data for ${page}`)
			return fallbackPageData as AEMPage
		}

		throw error
	}
}

export const useAEMContent = (page: AEMPageType, options?: AEMOptions) => {
	const [locale, setLocale] = useState(
		secureLocalStorage.getItem(StorageKeys.USER_PREFERRED_LANGUAGE) ||
			AvailableLanguages.EN_US
	)
	const { userProfile } = useAuthContext()

	useEffect(() => {
		const handleLanguageChange = () => {
			setLocale(
				secureLocalStorage.getItem(StorageKeys.USER_PREFERRED_LANGUAGE) ||
					AvailableLanguages.EN_US
			)
		}
		window.addEventListener(
			AppEvents.USER_LANGUAGE_CHANGED,
			handleLanguageChange
		)
		return () =>
			window.removeEventListener(
				AppEvents.USER_LANGUAGE_CHANGED,
				handleLanguageChange
			)
	}, [])

	const customQueryParams = options?.queryParams ?? {}

	const defaultQueryParams: AEMContentParams = {
		locale: locale as string,
		role: userProfile?.roleCode,
		office: userProfile?.reportingOfficeCode,
		country: userProfile?.countryCode
	}

	const finalQueryParams = {
		...defaultQueryParams,
		...customQueryParams
	}

	const optionParams = {
		brand: options?.brand,
		ship: options?.ship,
		uniqueId: options?.uniqueId,
		pathParams: options?.pathParams
	}

	return useQuery({
		queryKey: [
			QueryKeys.AEM_CONTENT,
			page,
			...Object.values(finalQueryParams),
			...Object.values(optionParams)
		],
		queryFn: () =>
			getAEMContent(page, { ...options, queryParams: finalQueryParams }),
		staleTime: 5 * 60 * 1000,
		gcTime: 10 * 60 * 1000,
		enabled: AVAILABLE_UNAUTHENTICATED_PAGES.includes(page) || !!userProfile
	})
}

export interface AEMComponentOptions {
	brand?: Brand
	ship?: string
}

export const useAEMComponents = (
	page: AEMPageType,
	options?: AEMComponentOptions
) => {
	const { data, isLoading, error } = useAEMContent(
		page,
		options
	) as UseQueryResult<AEMPage>

	const components = data?.items[0]?.components

	return { components, isLoading, error }
}

//This is for useEntityNavigation
export const useAEMEntityComponents = (
	page: AEMPageType,
	options?: AEMOptions
) => {
	const { data, isLoading, error } = useAEMContent(
		page,
		options
	) as UseQueryResult<AEMPage>
	const componentData = data?.items[0]?.components

	return { componentData, isLoading, error }
}

export interface AEMOptions {
	queryParams?: AEMContentParams
	uniqueId?: string
	pathParams?: string[]
	brand?: Brand
	ship?: string
}

export const useAEMComponentData = (
	page: AEMPageType,
	componentType: string,
	options?: AEMOptions
) => {
	const { data, isLoading, error, refetch } = useAEMContent(
		page,
		options
	) as UseQueryResult<AEMPage>
	const componentData = data?.items[0]?.components?.find(
		(component: AEMComponentType) =>
			component?.type === componentType &&
			(options?.uniqueId ? component.uniqueId === options.uniqueId : true)
	)

	return { componentData, isLoading, error, refetch }
}

export const useAEMNavData = (navType: AEMPageType) => {
	const { data, isLoading, error } = useAEMContent(
		navType
	) as UseQueryResult<AEMNavigation>
	return { data, isLoading, error }
}

export const AEMContentProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
export const useAEMContentRefresh = (pages: AEMPageType[]) => {
	const queryClient = useQueryClient()

	useEffect(() => {
		const refreshContent = (event: CustomEvent<{ locale: string }>) => {
			const locale = event.detail.locale
			for (const page of pages) {
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.AEM_CONTENT, page, locale]
				})
			}
		}

		window.addEventListener(
			AppEvents.USER_LANGUAGE_CHANGED,
			refreshContent as EventListener
		)
		return () =>
			window.removeEventListener(
				AppEvents.USER_LANGUAGE_CHANGED,
				refreshContent as EventListener
			)
	}, [pages, queryClient])
}
