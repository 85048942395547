import { PageWithNavigation } from "@components/templates/PageWithNavigation/PageWithNavigation"
import { Navigate, Route, Routes } from "react-router-dom"
import { AuthProvider } from "./context/AuthProvider"
import { lazy, Suspense, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import {
	RouteMetadata,
	updateTealiumPageData,
	getMetadataFromPath,
	initializeTealium
} from "./services/TealiumService"
import { AppShellProvider } from "./context/AppShellProvider"
import { ConditionalPageLayout } from "@components/templates/ConditionalPageLayout/ConditionalPageLayout"
import IsBrazil from "@components/templates/IsBrazil/IsBrazil"
import { useUserStore } from "@hooks/useUserStore"
import { useTokenRefresh } from "@hooks/useTokenRefresh"

const ProtectedOrUnprotectedRoute = lazy(
	() => import("./context/ProtectedOrUnprotectedRoute")
)
const ProtectedRoute = lazy(() => import("./context/ProtectedRoute"))
const PermissionRoute = lazy(() => import("./context/PermissionRoute"))
const LoginPage = lazy(() => import("./pages/LoginPage"))
const Home = lazy(() => import("./pages/home/home"))
const Logout = lazy(() => import("./pages/logout"))
const SeeOurShips = lazy(
	() => import("./pages/see-our-ships/SeeOurShipsRouting")
)
const PromotionHome = lazy(() => import("./pages/promotions/PromotionHome"))
const PromotionBrand = lazy(() => import("./pages/promotions/PromotionBrand"))
const PromotionsSearchResults = lazy(
	() => import("./pages/promotions/PromotionsSearchResults")
)
const PromotionDetail = lazy(() => import("./pages/promotions/PromotionDetail"))
const PromotionFeaturedOffers = lazy(
	() => import("./pages/promotions/PromotionFeaturedOffers")
)
const PromotionArchived = lazy(
	() => import("./pages/promotions/PromotionArchived")
)
const PromotionOffersList = lazy(
	() => import("./pages/promotions/PromotionOffersList")
)
const InsightsHome = lazy(() => import("./pages/insights/InsightsHome"))
const TermsOfUse = lazy(() => import("./pages/terms-of-use/TermsOfUse"))
const PrivacyPolicy = lazy(() => import("./pages/privacy-policy/PrivacyPolicy"))
const TravelAgentGuidelines = lazy(
	() => import("./pages/agent-guidelines/TravelAgentGuidelines")
)
const ErrorPage = lazy(() => import("./pages/error/ErrorPage"))

function getRouteMetadata(pathname: string): RouteMetadata {
	// Remove leading UI_BASE_URL if present
	const cleanPath = pathname.replace(/^\/ui/, "")
	return getMetadataFromPath(cleanPath)
}

function RouteAnalytics() {
	const location = useLocation()
	const { userProfile } = useUserStore()
	const [isScriptLoaded, setIsScriptLoaded] = useState(false)

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			const metadata = getRouteMetadata(location.pathname)

			if (!isScriptLoaded) {
				// First time initialization
				initializeTealium(metadata, userProfile ?? undefined)
				setIsScriptLoaded(true)
			} else {
				// Subsequent page views
				updateTealiumPageData(metadata, userProfile ?? undefined)
			}
		}, 100)

		return () => clearTimeout(timeoutId)
	}, [location.pathname, userProfile, isScriptLoaded])

	return null
}

// Routes that require specific tool code permissions
const permissionProtectedRoutes = [
	{ path: "/explore-our-fleet/*", component: SeeOurShips },
	{ path: "/promotion/home", component: PromotionHome },
	{ path: "/promotion/brand", component: PromotionBrand },
	{ path: "/promotion/results", component: PromotionsSearchResults },
	{ path: "/promotion/detail", component: PromotionDetail },
	{ path: "/promotion/featured-offers", component: PromotionFeaturedOffers },
	{ path: "/promotion/archived-offers", component: PromotionArchived },
	{ path: "/promotion/offers-list", component: PromotionOffersList },
	{ path: "/insights/*", component: InsightsHome }
]

// Routes that only require authentication
const securedOnlyRoutes = [
	{ path: "/home", component: Home },
	{ path: "/logout", component: Logout }
]

const securedAndUnsecuredRoutes = [
	{ path: "/terms-of-use", component: TermsOfUse },
	{ path: "/privacy-policy", component: PrivacyPolicy },
	{ path: "/travel-agent-guidelines", component: TravelAgentGuidelines }
]

function App() {
	return (
		<AppShellProvider>
			<AuthProvider>
				<AppContent />
			</AuthProvider>
		</AppShellProvider>
	)
}

function AppContent(): JSX.Element {
	useTokenRefresh()

	return (
		<>
			<RouteAnalytics />
			<Routes>
				<Route
					path="/login"
					element={
						<Suspense fallback={<div />}>
							<LoginPage />
						</Suspense>
					}
				/>
				<Route
					element={
						<Suspense fallback={<div />}>
							<ProtectedOrUnprotectedRoute />
						</Suspense>
					}
				>
					{securedAndUnsecuredRoutes.map(({ path, component: Component }) => (
						<Route
							key={path}
							path={path}
							element={
								<Suspense fallback={<div />}>
									<ConditionalPageLayout>
										<Component />
									</ConditionalPageLayout>
								</Suspense>
							}
						/>
					))}
				</Route>
				<Route
					element={
						<Suspense fallback={<div />}>
							<ProtectedRoute />
						</Suspense>
					}
				>
					{securedOnlyRoutes.map(({ path, component: Component }) => (
						<Route
							key={path}
							path={path}
							element={
								<Suspense fallback={<div />}>
									<IsBrazil>
										<PageWithNavigation>
											<Component />
										</PageWithNavigation>
									</IsBrazil>
								</Suspense>
							}
						/>
					))}
				</Route>
				<Route
					element={
						<Suspense fallback={<div />}>
							<PermissionRoute />
						</Suspense>
					}
				>
					{permissionProtectedRoutes.map(({ path, component: Component }) => (
						<Route
							key={path}
							path={path}
							element={
								<Suspense fallback={<div />}>
									<IsBrazil>
										<PageWithNavigation>
											<Component />
										</PageWithNavigation>
									</IsBrazil>
								</Suspense>
							}
						/>
					))}
				</Route>
				<Route path="*" element={<Navigate to="/home" replace />} />
				<Route
					path="/error"
					element={
						<Suspense fallback={<div />}>
							<ErrorPage />
						</Suspense>
					}
				/>
			</Routes>
		</>
	)
}

export default App
