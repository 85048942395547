import { UserProfile } from "@models/userProfile.model"
import secureLocalStorage from "react-secure-storage"

interface UtagData {
	page_name: string
	page_section: string
	agent_id?: string
	agency_name?: string
	agent_country?: string
	site_language: string
}

interface Utag {
	view: (data: UtagData) => void
	link: (data: UtagData) => void
}

declare global {
	interface Window {
		utag_data: UtagData
		utag: Utag
		utag_cfg_ovrd: {
			noview?: boolean
		}
	}
}

export interface RouteMetadata {
	section: string
	page: string
}

export function getMetadataFromPath(pathname: string): RouteMetadata {
	const cleanPath = pathname.replace(/^\/ui/, "").replace(/\/$/, "")
	const segments = cleanPath.split("/").filter(Boolean)
	const section = segments[0]
	const pageName = segments[segments.length - 1]
	return {
		section,
		page: pageName
	}
}

function createTealiumData(
	metadata: RouteMetadata,
	userProfile?: UserProfile
): UtagData {
	let utagData: UtagData = {
		page_name: `${metadata.section}: ${metadata.page}`,
		page_section: metadata.section,
		site_language:
			(secureLocalStorage.getItem("USER_PREFERRED_LANGUAGE") as string) || "EN"
	}

	if (userProfile) {
		utagData = {
			...utagData,
			agent_id: userProfile.agentId.toString(),
			agency_name: userProfile.agencyName,
			agent_country: userProfile.countryCode
		}
	}

	return utagData
}

export function initializeTealium(
	metadata: RouteMetadata,
	userProfile?: UserProfile
) {
	// Configure noview override before loading utag.js
	window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
	window.utag_cfg_ovrd.noview = true

	// Set initial utag_data
	window.utag_data = createTealiumData(metadata, userProfile)

	// Load Tealium script if not already loaded
	if (!document.getElementById("tealium-script")) {
		const script = document.createElement("script")
		script.id = "tealium-script"

		// Set script URL based on environment
		const env = import.meta.env.VITE_NODE_ENV === "production" ? "prod" : "qa"
		script.src = `https://tags.tiqcdn.com/utag/rccl/cruising-power/${env}/utag.js`
		script.async = true

		script.onerror = () => {
			console.error("[TealiumService] Failed to load Tealium script")
		}

		document.head.appendChild(script)
	}
}

export function updateTealiumPageData(
	metadata: RouteMetadata,
	userProfile?: UserProfile
) {
	const pageData = createTealiumData(metadata, userProfile)

	// Update window.utag_data
	window.utag_data = pageData

	// Trigger view event if utag is loaded
	if (window.utag?.view) {
		window.utag.view(pageData)
	} else {
		console.warn(
			"[TealiumService] utag.view not available - page view not tracked"
		)
	}
}
