import { useIdle } from "@mantine/hooks"
import { LoginError } from "@models/error/login-error"
import { useMutation } from "@tanstack/react-query"
import {
	BASE_LEGACY_URL_LOGOUT,
	IDLE_TIMEOUT_SECONDS,
	PENDING,
	USE_FORGEROCK
} from "@utils/constants.util"
import { StorageKeys } from "@utils/user-config"
import React, {
	createContext,
	useContext,
	useEffect,
	useState,
	useRef,
	useCallback
} from "react"
import {
	DismissedNotificationRequest,
	UserProfile
} from "../models/userProfile.model"
import { AuthService } from "../services/authService"
import { useUserStore } from "../hooks/useUserStore"
import { useAppShellContext } from "./AppShellProvider"
import { useNavigate } from "react-router-dom"
import secureLocalStorage from "react-secure-storage"
import { useRedirect } from "@hooks/useRedirect"
import { createDismissedNotification } from "@services/dismissedNotificationsService"

export interface AuthContextProps {
	userProfile: UserProfile | null
	setUserProfile: (userProfile: UserProfile) => void
	isAuthenticated: boolean
	hasSensitiveTool: (toolCode: string) => boolean
	isLoading: boolean
	login: (credentials: { username: string; password: string }) => Promise<{
		token: string
		userProfile: UserProfile
	}>
	logout: () => void
	error: LoginError
	dismissNotification: (
		notification: DismissedNotificationRequest
	) => Promise<void>
	refreshUserProfile: () => void
	refreshSession: (token: string, idToken?: string) => Promise<void>
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined)

export const useAuthContext = (): AuthContextProps => {
	const context = useContext(AuthContext)
	if (!context) {
		throw new Error("useAuthContext must be used within AuthProvider")
	}
	return context
}

interface AuthProviderProps {
	children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [error, setError] = useState<LoginError | null>(null)
	const setUserProfile = useUserStore((state) => state.setUserProfile)
	const userProfile = useUserStore((state) => state.userProfile)
	const idleTimerRef = useRef<number>(0)
	const intervalRef = useRef<NodeJS.Timeout | null>(null)
	const { handleRedirect } = useRedirect()
	const { closeMenu } = useAppShellContext()
	const navigate = useNavigate()

	const loginMutation = useMutation({
		mutationFn: async (credentials: { username: string; password: string }) => {
			const { token, id_token } = await AuthService.login(
				credentials.username,
				credentials.password
			)

			if (USE_FORGEROCK) {
				AuthService.setAuthToken(token, id_token || "")
			} else {
				AuthService.setAuthToken(token)
			}

			const userProfile = await AuthService.fetchProfile()
			return { token, id_token, userProfile }
		},
		onSuccess: ({ userProfile }) => {
			setUserProfile(userProfile)
			setIsAuthenticated(true)
			setError(null)
		},
		onError: (error: LoginError) => {
			setError(error)
		}
	})

	const logoutMutation = useMutation({
		mutationFn: async () => {
			AuthService.logout()
		},
		onSuccess: () => {
			setIsAuthenticated(false)
			AuthService.clearSession()
		},
		onError: (error: LoginError) => {
			setError(error)
		}
	})

	const isIdle = useIdle(IDLE_TIMEOUT_SECONDS, { initialState: false })

	const refreshSession = async (token: string, idToken?: string) => {
		try {
			if (!token) {
				throw new Error("No valid token provided")
			}

			AuthService.setAuthToken(token, idToken || "")
			const userProfile = await AuthService.fetchProfile()
			setUserProfile(userProfile)
			setIsAuthenticated(true)
			setError(null)
		} catch (error) {
			console.error("Failed to refresh session:", error)
			setError(error as LoginError)
			setIsAuthenticated(false)
			AuthService.clearSession()
		}
	}

	const dismissNotification = async (
		notification: DismissedNotificationRequest
	) => {
		try {
			createDismissedNotification({
				start: notification.start,
				end: notification.end,
				notificationId: notification.notificationId
			}).then(async () => {
				const updatedProfile = await refreshUserProfile()
				setUserProfile(updatedProfile)
			})
		} catch (error) {
			console.error("Failed to dismiss notification:", error)
		}
	}

	const refreshUserProfile = async (): Promise<UserProfile> => {
		const profile = await AuthService.fetchProfile()
		setUserProfile(profile)
		return profile
	}

	const checkLegacyToken = useCallback(async (): Promise<boolean> => {
		const urlParams = new URLSearchParams(window.location.search)
		const legacyToken = urlParams.get("z")

		if (legacyToken) {
			try {
				const result = await AuthService.validateLegacyToken(legacyToken)
				if (result?.access_token && result?.id_token) {
					await refreshSession(result.access_token, result.id_token)
					urlParams.delete("z")
					const cleanUrl =
						window.location.pathname +
						(urlParams.toString() ? `?${urlParams.toString()}` : "")
					window.history.replaceState(null, "", cleanUrl)
					return true
				}
			} catch (error) {
				console.error("Failed to validate legacy token:", error)
			}
		}
		return false
	}, [refreshSession])

	const handleLogout = useCallback(async () => {
		try {
			const legacyToken = new URLSearchParams(window.location.search).get("z")
			if (legacyToken) {
				const hasValidLegacyToken = await checkLegacyToken()
				if (hasValidLegacyToken) {
					return
				}
			}

			closeMenu()
			const hasUserNavigatedToLegacy = secureLocalStorage.getItem(
				StorageKeys.HAS_USER_NAVIGATED_TO_LEGACY
			)

			if (hasUserNavigatedToLegacy) {
				let ltpaTokenDeleted = false
				const cookies = document.cookie.split("; ")
				for (const cookie of cookies) {
					if (cookie.startsWith("LtpaToken")) {
						const cookieName = cookie.split("=")[0]
						document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;`
						ltpaTokenDeleted = true
					}
				}
				if (!ltpaTokenDeleted) {
					secureLocalStorage.removeItem(
						StorageKeys.HAS_USER_NAVIGATED_TO_LEGACY
					)
					window.location.href = `${BASE_LEGACY_URL_LOGOUT}`
				}
			}

			// Shutdown WebChat if it exists
			if (window.Pypestream) {
				// Call shutdown first
				const pypestream = window.Pypestream
				pypestream.shutdown()

				// Clean up the Pypestream instance from the window object
				// We need to use 'any' here because we're intentionally setting a required property to undefined
				// biome-ignore lint/suspicious/noExplicitAny: Intentionally using any to set a required property to undefined
				;(window as any).Pypestream = undefined
			}

			// Clean up the wcwConfig from the window object
			// We need to use 'any' here to access a dynamically added property
			// biome-ignore lint/suspicious/noExplicitAny: Intentionally using any to access a dynamically added property
			if ((window as any).wcwConfig) {
				// We need to use 'any' here because we're intentionally setting a property to undefined
				// biome-ignore lint/suspicious/noExplicitAny: Intentionally using any to set a property to undefined
				;(window as any).wcwConfig = undefined
			}

			// Clean up the WebChatWidgetGlobals from the window object
			// We need to use 'any' here to access a dynamically added property
			// biome-ignore lint/suspicious/noExplicitAny: Intentionally using any to access a dynamically added property
			if ((window as any).WebChatWidgetGlobals) {
				// We need to use 'any' here because we're intentionally setting a property to undefined
				// biome-ignore lint/suspicious/noExplicitAny: Intentionally using any to set a property to undefined
				;(window as any).WebChatWidgetGlobals = undefined
			}

			// Remove WebChat container
			const wcwContainer = document.getElementById("wcwContainer")
			if (wcwContainer) {
				document.body.removeChild(wcwContainer)
			}

			// Perform logout
			await logoutMutation.mutateAsync()

			// Add redirect to login page
			await navigate("/login")
		} catch (error) {
			console.error("Logout failed:", error)
		}
	}, [logoutMutation, handleRedirect, closeMenu, navigate, checkLegacyToken])

	useEffect(() => {
		if (isAuthenticated) {
			const lastActive = secureLocalStorage.getItem(
				StorageKeys.LAST_ACTIVE_TIME
			)
			if (
				lastActive &&
				Date.now() - Number.parseInt(lastActive as string) >
					IDLE_TIMEOUT_SECONDS
			) {
				;(async () => {
					await handleLogout()
				})()
				return
			}

			if (isIdle) {
				idleTimerRef.current = 0
				intervalRef.current = setInterval(async () => {
					idleTimerRef.current += 1
					if (idleTimerRef.current >= 5) {
						await handleLogout()
					}
				}, 1000)
			} else {
				secureLocalStorage.setItem(
					StorageKeys.LAST_ACTIVE_TIME,
					Date.now().toString()
				)
				if (intervalRef.current) {
					clearInterval(intervalRef.current)
					intervalRef.current = null
					idleTimerRef.current = 0
				}
			}
		}

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
				intervalRef.current = null
			}
		}
	}, [isIdle, isAuthenticated, handleLogout])

	useEffect(() => {
		const initializeProfile = async () => {
			const token = AuthService.getAuthToken()

			if (token) {
				try {
					const profile = await AuthService.fetchProfile()
					setUserProfile(profile)
					setIsAuthenticated(true)
				} catch {
					setIsAuthenticated(false)
					setUserProfile(null)
				}
			} else {
				setIsAuthenticated(false)
				setUserProfile(null)
			}
			setIsLoading(false)
		}

		initializeProfile().catch((error) => {
			console.error("Error initializing profile:", error)
			setIsLoading(false)
		})

		const handleCustomEvent = (event: CustomEvent) => {
			if (event.detail.key === StorageKeys.USER_PROFILE) {
				try {
					const updatedProfile = JSON.parse(event.detail.newValue || "{}")
					setUserProfile(updatedProfile)
				} catch (error) {
					console.error("Error parsing user profile:", error)
				}
			}
		}

		window.addEventListener(
			"localstorage-change",
			handleCustomEvent as EventListener
		)

		return () => {
			window.removeEventListener(
				"localstorage-change",
				handleCustomEvent as EventListener
			)
		}
	}, [])

	const hasSensitiveTool = (toolCode: string): boolean => {
		return Boolean(
			userProfile?.allowedApplicationTools?.some(
				(permission) => permission.toolCode === toolCode
			)
		)
	}

	return (
		<AuthContext.Provider
			value={{
				userProfile,
				setUserProfile,
				isAuthenticated,
				hasSensitiveTool,
				isLoading:
					isLoading ||
					loginMutation.status === PENDING ||
					logoutMutation.status === PENDING,
				login: loginMutation.mutateAsync,
				logout: handleLogout,
				error: (error ||
					loginMutation.error ||
					logoutMutation.error) as LoginError,
				dismissNotification,
				refreshUserProfile,
				refreshSession
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
