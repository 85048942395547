import { useAEMComponentData } from "@AEM/AEMContentLoader"
import { AEMComponentMapper, AEMPageType } from "@AEM/models/AEMPage.model"
import cPLogoDark from "@assets/svg/cp-logo-dark.svg"
import cPLogo from "@assets/svg/cp-logo.svg"
import ChatIcon from "@assets/svg/navigation/chat-bubble-alt.svg?react"
import DashboardIcon from "@assets/svg/navigation/dashboard.svg?react"
import LogoutIcon from "@assets/svg/navigation/logout.svg?react"
import { AEMImage } from "@components/AEMImage"
import { AccountMenu } from "@components/drawers/AccountMenu/AccountMenu"
import {
	NotificationItem,
	Notifications
} from "@components/drawers/Notifications/Notifications"
import { YourExtensions } from "@components/drawers/YourExtensions/YourExtensions"
import { LanguagePicker, ThemeSwitch } from "@components/ui"
import DrawerWithTrigger from "@components/ui/DrawerWithTrigger/DrawerWithTrigger"
import { NotificationBell } from "@components/ui/NotificationBell/NotificationBell"
import { getWeatherIcon } from "@components/ui/WeatherWidget/WeatherWidget"
import { getLocation } from "@hooks/useLocation"
import {
	AppShell,
	Avatar,
	Burger,
	Divider,
	Flex,
	Group,
	Image,
	Text,
	UnstyledButton,
	useMantineColorScheme
} from "@mantine/core"
import { getWeather } from "@services/weatherService"
import {
	VITE_USE_DARK_MODE,
	VITE_USE_LANGUAGE_PICKER
} from "@utils/constants.util"
import { useIsMobile, useIsTablet } from "@utils/device.utils"
import { useEffect, useState } from "react"
import { useAuthContext } from "../context/AuthProvider"
import styles from "./AppHeader.module.css"
import { useAppShellContext } from "../context/AppShellProvider"
import { useNavigate } from "react-router-dom"
import { useDismissedNotifications } from "@hooks/useDismissedNotifications"

function AppHeader() {
	const { isDismissed, dismissNotification } = useDismissedNotifications()
	const [notifications, setNotifications] = useState<NotificationItem[]>([])
	const { toggleMenu, isMenuOpen } = useAppShellContext()
	const { colorScheme } = useMantineColorScheme()
	const navigate = useNavigate()
	const isMobile = useIsMobile()
	const isTablet = useIsTablet()
	const { userProfile, isAuthenticated, logout } = useAuthContext()
	const { componentData } = useAEMComponentData(
		AEMPageType.NOTIFICATIONS,
		AEMComponentMapper.APP_NOTIFICATIONS
	)

	const [weatherIconPath, setWeatherIconPath] = useState<string>("")
	const [temperature, setTemperature] = useState<string>("")
	const { lat, long } = getLocation()

	const getWeatherData = async () => {
		if (lat && long) {
			let unit = ""
			if (lat && long) {
				unit = userProfile?.countryCode === "USA" ? "f" : "m"
			}
			const weather = await getWeather(`${lat},${long}`, unit)
			if (weather) {
				const code = weather.current?.weatherCode
				const temp = weather.current?.temperature
				setTemperature(`${temp}°${unit === "f" ? "F" : "C"}`)
				const icon = getWeatherIcon(code)
				setWeatherIconPath(icon)
			}
		}
	}

	useEffect(() => {
		getWeatherData()
	}, [userProfile, lat, long])

	useEffect(() => {
		if (componentData?.items) {
			const activeNotifications = componentData.items.filter(
				(notification: NotificationItem) => !isDismissed(notification.id)
			)
			setNotifications(activeNotifications)
		}
	}, [componentData, userProfile?.dismissedNotifications])

	const handleNotificationDismiss = async (id: string) => {
		if (!componentData?.items) {
			return
		}
		const selectedNotification = componentData.items.find(
			(notification: NotificationItem) => notification.id === id
		)
		if (selectedNotification?.start && selectedNotification?.end) {
			await dismissNotification({
				notificationId: selectedNotification.id,
				start: new Date(selectedNotification.start),
				end: new Date(selectedNotification.end)
			})
		}
	}

	const logo = colorScheme === "dark" ? cPLogoDark : cPLogo

	const IconDivider = ({ visibleFrom = "base" }) => (
		<Divider
			visibleFrom={visibleFrom}
			orientation="vertical"
			h={18}
			className={styles.divider}
		/>
	)
	const photo = userProfile?.agentPhotos?.AGENT_HOMEPAGE_PHOTO?.fullPath

	const username = isAuthenticated ? userProfile?.loginUserName : "Profile"
	const showLatteChat = isAuthenticated && userProfile?.latteAvailable

	const redirectTo = () => {
		const launchElement = document.getElementById("launch")
		launchElement ? launchElement?.click() : null

		const payload = {
			event_name: "chat_bot_engagement",
			page_url: window.location.href,
			link_url: "",
			chatbot_interaction_type: "Chat bot interaction type",
			event214: 1,
			page_name: document.title || "",
			page_section: "",
			site_language: navigator.language || "en"
		}
		window.utag.link(payload)
	}

	return (
		<AppShell.Header
			withBorder={false}
			h={{ base: 48, sm: 70 }}
			mt={{ base: 100, md: 50, lg: 50, xl: 50 }}
			className={styles.appShell}
		>
			<Group
				px={{ base: "sm", md: "xl" }}
				wrap="nowrap"
				align="center"
				justify="space-between"
				h="100%"
				w="100%"
			>
				<Group gap="md" wrap="nowrap">
					<Burger
						opened={isMenuOpen}
						color="var(--text-color-primary)"
						onClick={(e) => {
							e.stopPropagation()
							toggleMenu()
						}}
						aria-label="Toggle navigation"
						hiddenFrom="md"
						aria-expanded={isMenuOpen ? "true" : "false"}
					/>
					<UnstyledButton
						component="a"
						onClick={() => navigate("/home")}
						aria-label="Navigate to CruisingPower homepage"
					>
						<Image
							src={logo}
							alt="Company Logo"
							w={{ base: 199, md: 214, sm: 214 }}
							h={{ base: 28, md: 31, sm: 31 }}
						/>
					</UnstyledButton>
				</Group>
				<Flex
					gap={{ base: 6, md: "sm" }}
					justify="center"
					align="center"
					wrap="nowrap"
				>
					{/* Santiago Ponce: Business decided to remove the language picker for current version*/}
					{VITE_USE_LANGUAGE_PICKER && (
						<LanguagePicker
							showLabel={!isTablet && !isMobile}
							dataQaAttribute="header.button.language"
						/>
					)}
					<DrawerWithTrigger
						visibleFrom="sm"
						triggerIcon={<DashboardIcon data-qa="header.button.extensions" />}
						title="Your Extensions"
						drawerContent={<YourExtensions />}
						openTriggerLabel="Open Your Extensions"
						closeTriggerLabel="Close Your Extensions modal"
					/>
					<IconDivider visibleFrom="sm" />
					{showLatteChat && (
						<>
							<UnstyledButton
								visibleFrom="sm"
								display="flex"
								onClick={redirectTo}
								variant={"header"}
								aria-label={"Open Latte Chat service"}
							>
								<ChatIcon data-qa="header.button.latte" />
							</UnstyledButton>
							<IconDivider visibleFrom="sm" />
						</>
					)}
					<DrawerWithTrigger
						title="Your Notifications"
						openTriggerLabel="Open Notifications Modal"
						closeTriggerLabel="Close Notifications Modal"
						triggerIcon={<NotificationBell numNotis={notifications.length} />}
						drawerContent={
							<Notifications
								notifications={notifications}
								onDismiss={handleNotificationDismiss}
							/>
						}
					/>
					<IconDivider visibleFrom="sm" />
					<Group gap={4} wrap="nowrap">
						{weatherIconPath !== "" ? (
							<AEMImage
								imageData={{
									title: "",
									description: "",
									width: 80,
									height: 80,
									path: weatherIconPath
								}}
								style={{
									objectFit: "contain",
									minWidth: "24px",
									minHeight: "24px"
								}}
							/>
						) : (
							<></>
						)}
						<Text
							span
							fz={12}
							fw={500}
							lh={1.75}
							variant="nowrap"
							className={styles.weatherText}
						>
							{temperature}
						</Text>
					</Group>
					<IconDivider />
					<DrawerWithTrigger
						withHeader={false}
						triggerIcon={
							<Avatar
								variant="transparent"
								size={32}
								src={photo}
								alt={username}
								color="var(--text-color-primary)"
								data-qa="header.button.profile"
							/>
						}
						drawerContent={<AccountMenu />}
						openTriggerLabel="Open Your Profile modal"
						closeTriggerLabel="Close profile modal"
					/>
					{VITE_USE_DARK_MODE && (
						<>
							<IconDivider visibleFrom="sm" />
							<ThemeSwitch visibleFrom="sm" />
						</>
					)}
					<IconDivider visibleFrom="sm" />
					<UnstyledButton
						onClick={logout}
						variant="icon-hover"
						className="logoutBtn"
					>
						<Group visibleFrom="sm" gap="xs" data-qa="header.button.logout">
							<LogoutIcon />
							<Text span fz={9} fw={500} lh={2.2}>
								Log out
							</Text>
						</Group>
					</UnstyledButton>
				</Flex>
			</Group>
		</AppShell.Header>
	)
}

export default AppHeader
