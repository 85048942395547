import { create } from "zustand"
import { UserProfile } from "../models/userProfile.model"

interface UserStore {
	userProfile: UserProfile | null
	setUserProfile: (profile: UserProfile | null) => void
}

const VITE_COUNTRIES_WITH_FLEXPAY =
	import.meta.env.VITE_COUNTRIES_WITH_FLEXPAY?.split(",") || []

export const useUserStore = create<UserStore>((set) => ({
	userProfile: null,
	setUserProfile: (profile) => {
		set({
			userProfile: profile
				? {
						...profile,
						hasFlexPayAccess: VITE_COUNTRIES_WITH_FLEXPAY?.includes(
							profile?.countryCode || ""
						)
					}
				: null
		})
	}
}))
