import { api } from "./authService"
import axios from "axios"

export interface DismissedNotificationBody {
	start: Date
	end: Date
	notificationId: string
}

export interface DismissedNotificationRequest {
	start: Date
	end: Date
	notificationId: string
}

export async function createDismissedNotification(
	dismissedNotification: DismissedNotificationRequest
): Promise<{ result: boolean }> {
	try {
		const { data } = await api.post("/userProfile/dismissed-notification", {
			startDate: dismissedNotification.start,
			endDate: dismissedNotification.end,
			notificationId: dismissedNotification.notificationId
		})
		return data.data
	} catch (error) {
		if (axios.isAxiosError(error) && error.response) {
			throw new Error("Error fetching data")
		}
		throw error
	}
}
