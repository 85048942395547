import {
	AppShell,
	Box,
	Group,
	Image,
	UnstyledButton,
	useMantineTheme
} from "@mantine/core"
import { useMantineColorScheme } from "@mantine/core"
import cPLogoDark from "@assets/svg/cp-logo-dark.svg"
import cPLogo from "@assets/svg/cp-logo.svg"
import styles from "./PageWithHeader.module.css"
import AppFooter from "../../../navigation/AppFooter"
import { useNavigate } from "react-router-dom"
import { AEMSvg } from "@components/AEMSvg"
import { AEMComponentMapper, AEMPageType } from "@AEM/models/AEMPage.model"
import { useAEMComponentData } from "@AEM/AEMContentLoader"
import { ColorScheme } from "@mantine/styles"

export function PageWithHeader({ children }: { children: React.ReactNode }) {
	const theme = useMantineTheme()
	// TODO: Santiago Ponce: Should be changed with a global aem link, we are using login images for V1
	const { componentData } = useAEMComponentData(
		AEMPageType.LOGIN,
		AEMComponentMapper.LOGIN_FORM
	)
	const subsidiaryLogos = componentData?.additionalLogos

	interface CruiseLineLogo {
		dark: React.ReactNode
		light: React.ReactNode
	}

	const brandLogos: Record<string, CruiseLineLogo> = {
		royal: {
			light: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[1]?.path
					}}
					w={86}
					h={22}
				/>
			),
			dark: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[1]?.path,
						iconColor: theme.colors.royal[6]
					}}
					w={86}
					h={22}
				/>
			)
		},
		celebrity: {
			light: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[0]?.path
					}}
					w={106}
					h={22}
				/>
			),
			dark: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[0]?.path,
						iconColor: theme.colors.royal[6]
					}}
					w={106}
					h={22}
				/>
			)
		},
		silversea: {
			light: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[2]?.path,
						iconColor: theme.colors.royal[4]
					}}
					w={92}
					h={12}
				/>
			),
			dark: (
				<AEMSvg
					svgData={{
						path: subsidiaryLogos?.[2]?.path,
						iconColor: theme.colors.royal[6]
					}}
					w={92}
					h={12}
				/>
			)
		}
	}

	const { colorScheme } = useMantineColorScheme()
	const navigate = useNavigate()
	const logo = colorScheme === "dark" ? cPLogoDark : cPLogo

	return (
		<AppShell header={{ height: 60 }} padding="md" h="calc(100vh - 610px)">
			<Group
				px={{ base: 20, lg: 40 }}
				gap="lg"
				wrap="nowrap"
				className={styles.topBar}
				justify="flex-end"
			>
				{brandLogos.royal[colorScheme as ColorScheme]}
				{brandLogos.celebrity[colorScheme as ColorScheme]}
				{brandLogos.silversea[colorScheme as ColorScheme]}
			</Group>
			<AppShell.Header className={styles.appShell}>
				<Group px="md">
					<UnstyledButton
						onClick={() => navigate("/login")}
						aria-label="Navigate to CruisingPower homepage"
					>
						<Image
							src={logo}
							alt="Company Logo"
							w={{ base: 199, sm: 214 }}
							h={{ base: 28, sm: 31 }}
						/>
					</UnstyledButton>
				</Group>
			</AppShell.Header>
			<AppShell.Main p={0}>
				<Box h={"100dvh"} pt={110}>
					<Box h="100%" style={{ overflow: "auto" }}>
						{children}
						<AppShell.Footer pos="static">
							<AppFooter />
						</AppShell.Footer>
					</Box>
				</Box>
			</AppShell.Main>
		</AppShell>
	)
}
