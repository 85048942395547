import {
	Burger,
	Stack,
	UnstyledButton,
	Box,
	ScrollArea,
	Flex
} from "@mantine/core"
import LinksGroup, { LinkItem, LinkType } from "./LinksGroup"
import styles from "./AppMainMenu.module.css"
import { useClickOutside, useDisclosure } from "@mantine/hooks"
import { AssistanceModal } from "@components/ui/AssistanceModal/AssistanceModal"
import { IconLink } from "./IconLink"
import { useAEMNavData } from "@AEM/AEMContentLoader"
import {
	AEMLeafNode,
	AEMPageType,
	AEMSectionNode
} from "@AEM/models/AEMPage.model"
import { useAuthContext } from "../context/AuthProvider"
import { useState } from "react"
import { AEMSvg, AEMSvgData } from "@components/AEMSvg"
import { ESPRESSO_TOOL_CODE } from "@utils/constants.util"
import { useAppShellContext } from "../context/AppShellProvider"
import { useIsDesktop } from "@utils/device.utils"
import { svgToUrl } from "@utils/svgToUrl"

declare global {
	interface WindowEventMap {
		navigation_click: CustomEvent
		alert_bar_click: CustomEvent
		chat_bot_engagement: CustomEvent
		footer_click: CustomEvent
		hero_click: CustomEvent
		espresso_form_submission: CustomEvent
		callout_board_click: CustomEvent
		callout_board_promo_search: CustomEvent
		loyalty_rewards_click: CustomEvent
		group_leader_interaction: CustomEvent
		promotion_click: CustomEvent
		promotion_financial: CustomEvent
		header_click: CustomEvent
		financial_click: CustomEvent
		widget_interaction: CustomEvent
	}
}

function AppMainMenu() {
	const { data: collapsibleLinksComponentData } = useAEMNavData(
		AEMPageType.NAVIGATION_GLOBAL
	)
	const { data: iconLinksComponentData } = useAEMNavData(
		AEMPageType.NAVIGATION_SIDEBAR
	)

	const isDesktop = useIsDesktop()
	const { toggleMenu, isMenuOpen } = useAppShellContext()
	const [assistanceModalOpened, assistanceModalHandlers] = useDisclosure(false)
	const ref = useClickOutside(() => {
		if (isMenuOpen && isDesktop) {
			toggleMenu()
		}
	})

	const iconLinkElements: JSX.Element[] = []

	const [contactUsOpened, setContactusOpened] = useState(false)
	let contactUsIcon: AEMSvgData | undefined = undefined
	let helpIcon: AEMSvgData | undefined = undefined

	if (iconLinksComponentData) {
		const items = iconLinksComponentData[0]?.children.items
		for (const item of items) {
			if ("type" in item) {
				const linkItem: LinkItem = {
					icon: { path: svgToUrl(item.icon) } as AEMSvgData,
					label: item.label,
					link: item.link,
					toolCode: item.toolCode,
					type: item.type as LinkType,
					appId: item.appId
				}
				iconLinkElements.push(<IconLink key={item.label} item={linkItem} />)
			}
		}
		const contactUsNode =
			iconLinksComponentData[1]?.children.items[0] ?? undefined
		if (contactUsNode) {
			if ("icon" in contactUsNode) {
				contactUsIcon = {
					path: svgToUrl(contactUsNode.icon),
					title: contactUsNode.label
				} as AEMSvgData
			}
		}
		const helpNode = iconLinksComponentData[1]?.children.items[1] ?? undefined
		if (helpNode) {
			if ("icon" in helpNode) {
				helpIcon = {
					path: svgToUrl(helpNode.icon),
					title: helpNode.label
				} as AEMSvgData
			}
		}
	}

	const { hasSensitiveTool } = useAuthContext()
	const buildGlobalNav = (
		data: (AEMSectionNode | AEMLeafNode)[],
		opened: boolean
	): LinkItem[] => {
		const currentElements: LinkItem[] = []

		for (const item of data) {
			if ("children" in item) {
				if (item.children?.items) {
					const childLinks = buildGlobalNav(item.children.items, opened)
					if (childLinks.length > 0) {
						const element = {
							label: item.label,
							links: childLinks
						} as LinkItem
						currentElements.push(element)
					}
				}
			} else {
				const hasAccess =
					item.toolCode && item.toolCode !== ESPRESSO_TOOL_CODE
						? hasSensitiveTool(item.toolCode)
						: true
				if (hasAccess) {
					const element = {
						label: item.label,
						link: item.link,
						toolCode: item.toolCode,
						type: item.type as LinkType,
						appId: item.appId
					} as LinkItem
					/*
										if (item.toolCode === "SEE-OUR-SHIPS") {
											element.link = "/explore-our-fleet"
											element.type = "route"
										}
					*/
					currentElements.push(element)
				}
			}
		}
		return currentElements
	}

	const collapsibleLinkElements: JSX.Element[] = []
	const collapsibleContactUsLinkElements: JSX.Element[] = []
	let cruisingPowerLink: LinkItem | undefined = undefined

	if (collapsibleLinksComponentData) {
		const items = collapsibleLinksComponentData[0]?.children.items
		const linkItems = buildGlobalNav(items, isMenuOpen)

		for (const item of linkItems) {
			collapsibleLinkElements.push(
				<LinksGroup
					key={item.label}
					menuOpened={false}
					label={item.label ?? ""}
					links={item.links}
				/>
			)
		}

		const bottomLinks = buildGlobalNav(
			collapsibleLinksComponentData[1]?.children.items,
			isMenuOpen
		)
		const contactUsLinks = bottomLinks[0]
		if (contactUsLinks) {
			collapsibleContactUsLinkElements.push(
				<LinksGroup
					key={contactUsLinks.label}
					menuOpened={contactUsOpened}
					label={contactUsLinks.label ?? ""}
					links={contactUsLinks.links}
				/>
			)
		}
		const helpLink = bottomLinks[1]
		if (helpLink) {
			cruisingPowerLink = helpLink
		}
	}

	const toggleMenuAndTrack = () => {
		// Track the navigation event
		const payload = {
			event_name: "navigation_click",
			nav_link_text: "",
			interaction_type: !isMenuOpen ? "Widget Open" : "Widget Close",
			category: "Navigation",
			link_url: "",
			page_url: window.location.href,
			event211: 1,
			page_name: document.title || "",
			page_section: "",
			site_language: navigator.language || "en"
		}
		window.utag.link(payload)
		toggleMenu()
	}

	const handleContactUsClick = () => {
		if (!isMenuOpen) {
			toggleMenuAndTrack
			setContactusOpened(true)
		} else {
			setContactusOpened(!contactUsOpened)
		}
	}

	return (
		<Stack
			id="app-main-menu"
			mih={{ base: "100%", sm: "calc(100% - 100px)", md: "100%" }}
			mt={{ base: 0 }}
			ref={ref}
		>
			<Burger
				opened={isMenuOpen}
				onClick={toggleMenuAndTrack}
				aria-label="Toggle navigation"
				visibleFrom="md"
				ml={19}
				className={styles.burger}
				color="var(--text-color-primary)"
				aria-expanded={isMenuOpen ? "true" : "false"}
			/>
			<Box className={styles.menuContent}>
				{isMenuOpen ? (
					<ScrollArea className={styles.scrollArea}>
						{collapsibleLinkElements}
					</ScrollArea>
				) : (
					<Stack gap={0} align="center">
						{iconLinkElements}
					</Stack>
				)}
			</Box>
			<Box>
				<Flex className={styles.navBottom}>
					<UnstyledButton
						onClick={handleContactUsClick}
						className={styles.bottomButton}
						component={isMenuOpen ? "span" : "button"}
					>
						{contactUsIcon && (
							<AEMSvg
								ariaRole={isMenuOpen ? "img" : "button"}
								svgData={contactUsIcon}
							/>
						)}
					</UnstyledButton>
					<Box
						className={styles.contactUs}
						display={isMenuOpen ? "block" : "none"}
					>
						{collapsibleContactUsLinkElements}
					</Box>
				</Flex>
				<Flex className={styles.navBottom}>
					<UnstyledButton
						onClick={assistanceModalHandlers.open}
						className={styles.bottomButton}
						component={isMenuOpen ? "span" : "button"}
						aria-label="Help And Assistance"
					>
						{helpIcon && (
							<AEMSvg
								ariaRole={isMenuOpen ? "img" : "button"}
								svgData={helpIcon}
							/>
						)}
					</UnstyledButton>
					<UnstyledButton
						onClick={assistanceModalHandlers.open}
						className={styles.bottomAnchor}
						display={isMenuOpen ? "block" : "none"}
						ml={16}
					>
						{cruisingPowerLink?.label}
					</UnstyledButton>
				</Flex>
			</Box>
			<AssistanceModal
				opened={assistanceModalOpened}
				onClose={assistanceModalHandlers.close}
			/>
		</Stack>
	)
}

export default AppMainMenu
