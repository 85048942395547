import { Flex, MantineStyleProps } from "@mantine/core"
import styles from "./AEMSvg.module.css"

export interface AEMSvgData {
	path: string // This is meant to be an url
	title?: string | null
	description?: string
	iconColor?: string
}

interface AEMSvgProps extends MantineStyleProps {
	svgData: AEMSvgData
	ariaRole?: React.AriaRole
}

export const AEMSvg: React.FC<AEMSvgProps> = ({
	svgData,
	ariaRole = "img",
	h,
	w,
	...props
}) => {
	return (
		<Flex
			className={styles.icon}
			style={{
				"--icon-url": `url(${svgData?.path})`,
				"--svg-color": svgData?.iconColor
			}}
			h={h ?? "24px"}
			w={w ?? "24px"}
			role={ariaRole}
			aria-label={svgData?.description || svgData?.title || ""}
			{...props}
		/>
	)
}
