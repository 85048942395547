import { useEffect, useRef } from "react"
import { AuthService } from "../services/authService"
import { useAuthContext } from "../context/AuthProvider"
import { USE_FORGEROCK } from "@utils/constants.util"

// For testing purposes:
// const REFRESH_INTERVAL = 30 * 1000 // 30 seconds

// Later for production:
const REFRESH_INTERVAL = 45 * 60 * 1000 // 45 minutes

export const useTokenRefresh = (): void => {
	const refreshTimeoutRef = useRef<NodeJS.Timeout>()
	const { isAuthenticated, refreshSession } = useAuthContext()

	useEffect(() => {
		// Skip token refresh for legacy authentication
		if (!USE_FORGEROCK) return

		const setupRefreshTimer = () => {
			if (refreshTimeoutRef.current) {
				clearTimeout(refreshTimeoutRef.current)
			}
			if (!isAuthenticated) return

			refreshTimeoutRef.current = setTimeout(async () => {
				try {
					const refreshToken = AuthService.getRefreshToken()
					if (refreshToken) {
						const { access_token, refresh_token, id_token } =
							await AuthService.refreshAccessToken()
						AuthService.setAuthToken(
							access_token,
							AuthService.getIdToken(),
							refresh_token
						)
						await refreshSession(access_token, id_token)

						setupRefreshTimer()
					}
				} catch (error) {
					console.error("Failed to refresh token:", error)
				}
			}, REFRESH_INTERVAL)
		}

		setupRefreshTimer()

		return () => {
			if (refreshTimeoutRef.current) {
				clearTimeout(refreshTimeoutRef.current)
			}
		}
	}, [isAuthenticated, refreshSession])

	if (process.env.NODE_ENV === "development") {
		useEffect(() => {
			if (USE_FORGEROCK) {
				console.log(
					"Token refresh scheduled in:",
					Math.floor(REFRESH_INTERVAL / 1000 / 60),
					"minutes"
				)
			} else {
				console.log("Token refresh disabled - using legacy authentication")
			}
		}, [])
	}
}
