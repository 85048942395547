import { useAEMComponentData } from "@AEM/AEMContentLoader"
import { AEMPageType } from "@AEM/models/AEMPage.model"
import { AEMComponentMapper } from "@AEM/models/AEMPage.model"
import ArrowRightIcon from "@assets/svg/arrow-right.svg?react"
import ArrowLeftIcon from "@assets/svg/arrow.svg?react"
import { Carousel, Embla } from "@mantine/carousel"
import { ActionIcon, Anchor, Group, Text } from "@mantine/core"
import { useCallback, useEffect, useState } from "react"
import styles from "./AlertBar.module.css"
import carouselStyles from "./Carousel.module.css"
import { useRedirect } from "@hooks/useRedirect"
import { LinkType } from "navigation/LinksGroup"

interface AlertItem {
	alertDescription: {
		markdown: string
	}
	start: string | null
	end: string | null
	country: string | null
	link: string | null
	appId: string | null
	toolCode: string | null
	type: LinkType | null
}

interface AlertBarProps {
	className: string
	onClose: () => void
}

export function AlertBar({ className }: AlertBarProps) {
	const [embla, setEmbla] = useState<Embla | null>(null)
	const [selected, setSelected] = useState(1)
	const numSlides = embla ? embla.slideNodes().length : 0
	const { handleRedirect } = useRedirect()
	const { componentData, isLoading } = useAEMComponentData(
		AEMPageType.ALERTS,
		AEMComponentMapper.APP_ALERTS
	)

	const handleSelect = useCallback(() => {
		if (!embla) return
		const slide = embla.selectedScrollSnap()
		setSelected(slide + 1)
	}, [embla])

	useEffect(() => {
		if (embla) {
			embla.on("select", handleSelect)

			return () => {
				embla.off("select", handleSelect)
			}
		}

		return undefined
	}, [embla, handleSelect])

	if (isLoading || !componentData?.items?.length) {
		return null
	}
	const alerts = componentData?.items

	const handleAlertToggle = (action?: "link" | "close") => {
		const payload = {
			event_name: "alert_bar_click",
			page_url: window.location.href,
			alert_bar_content: action === "link" ? "Arrow Click" : "Close Alertbar",
			interaction_type: "Alert Bar Content",
			category: "Alert Bar Navigation",
			event211: 1,
			page_name: document.title || "",
			page_section: "",
			site_language: navigator.language || "en"
		}
		window.utag.link(payload)
	}

	return (
		<Group className={className} wrap="nowrap">
			<Carousel
				classNames={carouselStyles}
				withControls={false}
				getEmblaApi={setEmbla}
				loop={true}
				slideGap="sm"
				align="start"
			>
				{alerts.map((alert: AlertItem, index: number) => (
					<Carousel.Slide key={index}>
						<Anchor
							component="button"
							underline="hover"
							onClick={() =>
								handleRedirect({
									link: alert.link ?? undefined,
									type: alert.type ?? undefined,
									appId: alert.appId ?? undefined
								})
							}
						>
							<Text
								className={styles.alertText}
								role="alert"
								aria-live="polite"
							>
								{alert.alertDescription.markdown}
							</Text>
						</Anchor>
					</Carousel.Slide>
				))}
			</Carousel>

			<Group gap="xs" wrap="nowrap">
				{numSlides > 1 && (
					<Group gap="xs" wrap="nowrap">
						<ActionIcon
							variant="transparent"
							className={styles.carouselArrow}
							onClick={() => {
								embla?.scrollPrev()
								handleAlertToggle("link")
							}}
							data-qa="alert.button.previous"
						>
							<ArrowLeftIcon />
						</ActionIcon>
						<Text className={styles.carouselPaginationText}>
							{selected} of {numSlides}
						</Text>
						<ActionIcon
							variant="transparent"
							className={styles.carouselArrow}
							onClick={() => {
								embla?.scrollNext()
								handleAlertToggle("link")
							}}
							data-qa="alert.button.next"
						>
							<ArrowRightIcon />
						</ActionIcon>
					</Group>
				)}
			</Group>
		</Group>
	)
}
