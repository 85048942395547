import { useAuthContext } from "../context/AuthProvider"

export const useDismissedNotifications = () => {
	const { userProfile, dismissNotification } = useAuthContext()

	const isDismissed = (notificationId: string) => {
		const now = new Date().toISOString()
		return (
			userProfile?.dismissedNotifications?.some(
				(notification) =>
					notification.notificationId === notificationId ||
					new Date(notification.start) <= new Date(now) ||
					new Date(notification.end) >= new Date(now)
			) || false
		)
	}

	return {
		dismissedNotifications: userProfile?.dismissedNotifications || [],
		isDismissed,
		dismissNotification
	}
}
