import { changeLocationData } from "@services/authService"
import { useAuthContext } from "../context/AuthProvider"

export const getLocation = (lat?: string, long?: string) => {
	const { userProfile } = useAuthContext()
	if (lat && long) {
		changeLocationData(userProfile, lat, long)
	}

	return {
		lat: lat ?? userProfile?.locationData?.find((v) => v.key === "lat")?.value,
		long:
			long ?? userProfile?.locationData?.find((v) => v.key === "long")?.value
	}
}
