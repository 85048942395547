import {
	Image,
	Stack,
	Group,
	Text,
	UnstyledButton,
	Divider,
	Anchor
} from "@mantine/core"

import styles from "./Notifications.module.css"
import { LinkType } from "navigation/LinksGroup"
import { useRedirect } from "@hooks/useRedirect"

export interface NotificationItem {
	id: string
	icon?: {
		_publishUrl: string
	}
	featuredImage?: NotificationImage
	message: string
	dismissLabel: string
	start: string | null
	end: string | null
	link?: string
	label?: string
	type?: LinkType
	appId?: string
	toolCode?: string
}

interface NotificationImage {
	_dynamicUrl: string
	_dmS7Url: string
	title: string
	description: string
	width: number
	height: number
}

interface NotificationMessageProps extends NotificationItem {
	onDismiss: (uniqueId: string) => void
}

const NotificationMessage = ({
	id,
	featuredImage,
	message,
	icon,
	link,
	label,
	type,
	appId,
	toolCode,
	dismissLabel,
	onDismiss
}: NotificationMessageProps) => {
	const { handleRedirect } = useRedirect()

	return (
		<Group align="flex-start" wrap="nowrap">
			{featuredImage && (
				<Image src={featuredImage._dmS7Url} w={110} h={110} radius={12} />
			)}
			{icon && <Image src={icon._publishUrl} />}

			<Stack flex={1} gap="xs">
				<Text fz={13} lh={1.4}>
					{message}
				</Text>

				<Group justify="space-between">
					<Anchor
						onClick={() =>
							handleRedirect({
								link,
								label,
								type,
								appId,
								toolCode
							})
						}
						fz={12}
						fw={500}
						lh={1.7}
						className={styles.messageLink}
						underline="always"
						aria-label={`View message, ${message}`}
						id={`view-notification-${id}`}
					>
						{label}
					</Anchor>

					<UnstyledButton
						fz={12}
						fw={500}
						lh={1.7}
						td="underline"
						className={styles.messageLink}
						id={`dismiss-notification-${id}`}
						onClick={() => onDismiss(id)}
						aria-label={`Dismiss notification, ${message}`}
					>
						{dismissLabel}
					</UnstyledButton>
				</Group>
			</Stack>
		</Group>
	)
}

interface NotificationsProps {
	notifications: NotificationItem[]
	onDismiss: (uniqueId: string) => void
}

export function Notifications({
	notifications,
	onDismiss
}: NotificationsProps) {
	if (!notifications.length) return null

	const focusNextItem = (nextItemIndex: number) => {
		const dismissedNotification = document.activeElement?.closest(
			".notificationItem"
		) as HTMLElement | null
		const closeButton = dismissedNotification
			?.closest("section")
			?.querySelector("header > button") as HTMLElement | undefined
		let buttonToFocus = closeButton
		if (nextItemIndex < notifications.length) {
			// Go to next item
			const nextViewButton = document.getElementById(
				`view-notification-${notifications[nextItemIndex].id}`
			)
			const nextDismissButton = document.getElementById(
				`dismiss-notification-${notifications[nextItemIndex].id}`
			)
			buttonToFocus = nextViewButton?.hasAttribute("href")
				? nextViewButton
				: nextDismissButton ?? closeButton
		}
		if (nextItemIndex === notifications.length && notifications.length > 1) {
			// Go to first item
			const firstViewButton = document.getElementById(
				`view-notification-${notifications[0].id}`
			)
			const firstDismissButton = document.getElementById(
				`dismiss-notification-${notifications[0].id}`
			)
			buttonToFocus = firstViewButton?.hasAttribute("href")
				? firstViewButton
				: firstDismissButton ?? closeButton
		}

		buttonToFocus?.focus()
	}

	return (
		<Stack gap="lg" justify="space-between" pt={0} pb="xl" px="xl">
			{notifications.map((notification, index) => (
				<Stack key={notification.id} className={"notificationItem"}>
					<NotificationMessage
						onDismiss={(uniqueId) => {
							focusNextItem(index + 1)
							onDismiss(uniqueId)
						}}
						{...notification}
					/>
					{index < notifications.length - 1 && <Divider />}
				</Stack>
			))}
		</Stack>
	)
}
